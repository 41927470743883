import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This `}<inlineCode parentName="p">{`Getting Started`}</inlineCode>{` guide is intended to get you up and running with the KOPE web app as quickly as possible.
Follow the steps below in order for the best experience.`}</p>
    </PageDescription>
    <h3>{`Contents`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/register-for-kope"
          }}><strong parentName="a">{`Register for KOPE`}</strong></a>{` `}<br />{`Embark on your KOPE journey by registering yourself and your organization.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/set-up"
          }}><strong parentName="a">{`Set Up`}</strong></a>{` `}<br />{`Define your role `}<inlineCode parentName="p">{`supplier`}</inlineCode>{` or `}<inlineCode parentName="p">{`specifier`}</inlineCode>{` and customize your profile details.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/products"
          }}><strong parentName="a">{`Products`}</strong></a>{` `}<br />{`As a supplier, showcase and detail your products for offsite construction.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/supply-chains"
          }}><strong parentName="a">{`Supply Chains`}</strong></a>{` `}<br />{`Prepare all the offsite products you need for your project using Supply Chains.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/projects"
          }}><strong parentName="a">{`Projects`}</strong></a>{` `}<br />{`Create, organize, and apply 3D models for seamless offsite product integration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/configurator"
          }}><strong parentName="a">{`Configurator`}</strong></a>{` `}<br />{`Dive into product configurations, tweaking and applying them within your project environment.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/results"
          }}><strong parentName="a">{`Results`}</strong></a>{` `}<br />{`Review, compare, and save outcomes to identify the perfect solution for your project.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/getting-started/outputs"
          }}><strong parentName="a">{`Outputs`}</strong></a>{` `}<br />{`Generate and download drawings and models as deliverables for external use.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      